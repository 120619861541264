/* eslint-disable */

import React from 'react';

import Skeleton from 'react-loading-skeleton';
import ReactTooltip from 'react-tooltip';

function ProductNamePrice(props) {
  return (
    <div className={props.mobile ? 'mobile-only' : 'desktop-only'}>
      <div id={'product-brand-name'}>
        {props.product.product.brand && <img alt='' src={props.product.product.brand.logo} width={props.mobile ? 60 : 50} />}
      </div>
      <h1>{props.product.product.title || <Skeleton />}</h1>
      <div className={'product-price'}>
        {(props.variant.price && <span className={'current-price'}>{props.variant.price} kr</span>) || (
          <Skeleton width={70} />
        )}
        <span className={'old-price'}>
          {/* {props.variant.compare_at_price !== props.variant.price ? props.variant.compare_at_price + ' kr' : ''}{' '} */}
        </span>
        {/* {props.variant.price_type !== 'offer' && (
          <span className='info-price' data-tip data-for={`bonusPrice-${props.mobile ? 2 : 1}`}>
            <img alt={''} src='https://img.icons8.com/ios/50/FF5B23/info--v1.png' className='info-bonus' />
            Pluss 10-30% bonus!
          </span>
        )} */}

        {props.variant.price_type === 'offer' && (
          <span className='info-price' data-tip data-for={`bonusPrice-${props.mobile ? 2 : 1}`}>
            <img alt={''} src='https://img.icons8.com/ios/50/FF5B23/info--v1.png' className='info-bonus' />
            Membership discount!
          </span>
        )}
        <ReactTooltip
          id={`bonusPrice-${props.mobile ? 2 : 1}`}
          globalEventOff={'click'}
          type='light'
          place='bottom'
          multiline={true}
          event='click'
          effect='solid'
          offset={{ top: 0, left: props.mobile ? -10 : 10 }}
          border={true}
          clickable={true}
        >
          <span>
            The discounted price is available only for memberhip customers. If you're not yet a member you can become one by
            consenting with checkbox below buy button.
          </span>
        </ReactTooltip>
      </div>
    </div>
  );
}

export default ProductNamePrice;
