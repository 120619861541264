type EnvVariables = {
  apiUrl: string;
  cookieDomain: string;
  gaId: string;
  gaEnabled: boolean;
  sentryDSN: string;
  sentryEnv: string;
  sentryTraceRate: number;
};

export const envVariables: EnvVariables = {
  apiUrl: process.env.REACT_APP_MINISTORE_API_URL || '',
  cookieDomain: process.env.REACT_APP_MINISTORE_COOKIE_DOMAIN || '',
  gaId: process.env.REACT_APP_MINISTORE_GA_ID || '',
  gaEnabled: process.env.REACT_APP_MINISTORE_GA_ENABLED ? process.env.REACT_APP_MINISTORE_GA_ENABLED === 'true' : false,
  sentryDSN: process.env.REACT_APP_MINISTORE_SENTRY_DSN || '',
  sentryEnv: process.env.REACT_APP_MINISTORE_SENTRY_ENV || '',
  sentryTraceRate: process.env.REACT_APP_MINISTORE_TRACE_SAMPLE_RATE
    ? Number(process.env.REACT_APP_MINISTORE_TRACE_SAMPLE_RATE)
    : 0
};
