import { EventCategory, EventLabel, EventName, trackEvent } from '../../../analytics/analytics';

const trackOverlayClickMessageEvent = (event: MessageEvent) => {
  const OVERLAY_CLICKED_MESSAGE_DATA = 'ministore:ml:sideStore:close';
  if (event?.data === OVERLAY_CLICKED_MESSAGE_DATA) {
    trackEvent({
      eventName: EventName.CLOSE_OVERLAY_CLICK,
      label: EventLabel.CLOSE,
      category: EventCategory.CLICK
    });
  }
};

export const trackOverlayClicks = () => {
  window.addEventListener('message', trackOverlayClickMessageEvent);
};
