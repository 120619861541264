import React from 'react';

import creditCard from './assets/credit-card.svg';
import vippsOrange from './assets/vipps-orange.svg';
import shield from './assets/verified.svg';

export function PaymentInformation() {
  return (
    <div className={'additional-information'}>
      {/* <div className={''}>
        <div className={'second-col'}>
          <img src={shield} width={15} alt={''} id={'priv-logo'} />
          PERSONOPPLYSNINGER
        </div>
        <div className={'first-col'}>
          <img src={creditCard} alt={'SIKKER BETALING MED'} id={'card-logo'} />
          SIKKER BETALING MED
          <img src={vippsOrange} alt={'vipps logo'} id={'vipps-logo'} />
        </div>
      </div> */}
    </div>
  );
}
