/* eslint-disable */

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as Icon from 'react-icons/fi';
import Checkbox from 'react-custom-checkbox';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Motion, spring } from 'react-motion';
import ReactModal from 'react-modal';
import PulseLoader from 'react-spinners/PulseLoader';
import { css } from '@emotion/react';

import ProductDescription from './ProductDescription';
import { AdditionalInformation } from './AdditionalInformation';
import ProductNamePrice from './ProductNamePrice';
import ProductImages from './ProductImages';
import { PaymentInformation } from './PaymentInformation';
import { trackEvent, trackPage } from './analytics/analytics';

import header_logo_barbershop from './assets/barbershop-logo-no-white-600_250x@2x.webp';
import header_logo_antonsport from './assets/anton_logo.png';
import { envVariables } from './env-variables';

const override = css`
  display: block;
  margin: 0 auto;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 60px;
  top: 46px;
`;

function ProductPage() {
  useEffect(() => {
    // trackPage();
  }, []);

  const api_url = envVariables.apiUrl;

  const { productId, vendorId, variantId } = useParams();

  const routeParams = useParams();
  const history = useHistory();
  const [data, setData] = useState({
    product: {
      images: [],
      variants: [{ id: 0, name: '', price: 0, compare_at_price: 0 }]
    }
  });
  const [openModal, setOpenModal] = useState(false);
  const [openPopup, setOpenPopup] = useState(true);
  const [error, setError] = useState(false);
  const [variants, setVariants] = useState([]);
  const [quantity] = useState(1);
  const [customerConsent, setCustomerConsent] = useState(true);
  const [authorizePaymentInProgress, setAuthorizePaymentInProgress] = useState(false);
  const [images, setImages] = useState([]);
  const [currentVariant, setCurrentVariant] = useState({ id: 0, name: '', price: 0, compare_at_price: 0, inventory_quantity: 0, title: ''});
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [currentName, setCurrentName] = useState(null);
  const [currentSize, setCurrentSize] = useState(null);

  const [option1, setOption1] = useState(null);
  const [option2, setOption2] = useState(null);
  const [option3, setOption3] = useState(null);

  const [options1, setOptions1] = useState(null);
  const [options2, setOptions2] = useState(null);
  const [options3, setOptions3] = useState(null);

  const [options, setOptions] = useState(null);
  useEffect(() => {
    async function fetchData() {
      const result = await axios({
        method: 'GET',
        url: `${api_url}/products/${productId}/vendors/${vendorId}/`
      }).catch(() => {
        setError(true);
      });
      setData(result.data);
      setVariants(result.data.product.variants);
      setOptions(result.data.product.options);
    }

    fetchData().catch(() => {
      setError(true);
    });
  }, [productId]);

  useEffect(() => {
    if (variants.length) {

      if (!variantId) {
        setCurrentVariant(variants[0]);
        history.push(`/${vendorId}/${productId}/${variants[0].id}`);
        setCurrentName(variants[0].title);
      } else {
        setCurrentVariant(variants.find(v => v.id === parseInt(variantId, 10)));
      }
    }
  }, [data, variants, currentName, variantId]);

  const makePayment = async () => {
    setAuthorizePaymentInProgress(true);
    // trackEvent({ eventName: 'payButtonClicked', category: 'Vipps Buton', label: 'vipps button clicked' });

    const req = await axios({
      method: 'POST',
      url: `${api_url}/vipps/vendors/${vendorId}`,
      data: {
        price: parseFloat(currentVariant.price),
        createCustomerConsent: customerConsent,
        title: `${data.product.title} (${currentVariant.title})`,
        quantity: 1,
        variant_id: currentVariant.id,
        product_id: productId
      }
    }).catch(() => alert('betalingsfeil'));

    if (req && (req.status === 200 || req.status === 201) && !req.hasOwnProperty('errorCode')) {
      window.location = req.data.url;
    } else {
      alert('betalingsfeil');
    }
  };

  const initialErrorStyle = () => {
    return {
      y: spring(295, { stiffness: 190, damping: 25 })
    };
  };

  const finalErrorStyle = () => {
    return {
      y: spring(-45, { stiffness: 190, damping: 25 })
    };
  };

  let modalStyle = openModal ? finalErrorStyle() : initialErrorStyle();

  const handleCloseModal = () => {
    trackEvent({ eventName: 'modal', category: 'PrivacyModal', label: 'close' });

    setOpenModal(false);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleConsentChange = () => {
    if (customerConsent) {
      setCustomerConsent(false);
    } else {
      setCustomerConsent(true);
    }
  };

  const changeColor = color => {
    const newVariant = variants.find(p => p.name === color);
    history.push(`/${vendorId}/${productId}/${newVariant.id}`);
    setCurrentVariant(newVariant.id);
    setCurrentName(color);
  };

  const setSize = size => {
    setCurrentSize(size);
    setCurrentVariant(size);
  };

  return (
    <>
      {!error && (
        <div className=''>
          <div className=''>
            <ProductNamePrice product={data} variant={currentVariant} mobile />
            <div className={''}>
              <ProductImages images={data.product.images} variant={currentVariant} />
              {[...new Set(colors)].length > 1 && (
                <div className={'choose-color'}>
                  Velg farge ({currentName}):
                  <ul>
                    {[...new Set(colors)].map(v => {
                      return (
                        <li
                          key={v}
                          onClick={() => changeColor(v)}
                          className={`available-true ${currentName === v ? 'selected' : ''}`}
                        >
                          <img src={variants.find(p => p.name === v).thumbnail} />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}

              {sizes.length > 1 && (
                <div className={'choose-size'}>
                  {/* VELG STØRRELSE */}
                  <ul>
                    {[...new Set(sizes)].map(v => {
                      return (
                        <li
                          className={`available-${v.available} ${
                            !!currentSize && currentSize.id === v.id ? 'selected' : ''
                          }`}
                          onClick={() => {
                            if (!!v.available) {
                              setSize(v);
                            }
                          }}
                          key={v.id}
                        >
                          {v.size}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
            <div className={''}>
              <ProductNamePrice product={data} variant={currentVariant} />
              <div className='variant-select'>
                <select
                  value={currentVariant ? currentVariant.id : ''}
                  onChange={(e) => {
                    const variantId = e.target.value;
                    const selected = variants.find(
                      (v) => v.id === parseInt(variantId,10)
                    );

                    history.push(`/${vendorId}/${productId}/${selected.id}`);
                  }}
                >
                  <option value="" disabled>Select a variant</option>
                  {variants.map((variant) => (
                    <option key={variant.id} value={variant.id} disabled={variant.inventory_quantity < 1}>
                      {variant.title}{variant.inventory_quantity < 1 ? ' Utsolgt' : ''}
                    </option>
                  ))}
                </select>
              </div>
              { currentVariant.inventory_quantity > 0 && (
                <div className={'button-wrapper'}>
                  <button
                    className='button vipps'
                    disabled={authorizePaymentInProgress}
                    onClick={makePayment}
                  >
                    Vipps Hurtigkasse
                  </button>
                  <PulseLoader css={override} size={15} color={'white'} loading={authorizePaymentInProgress} />
                </div>
              )}
            </div>
            <PaymentInformation />
          </div>
        </div>
      )}
      {error && (
        <div className='ProductPage'>
          <div className='container'>
            <header className={`ProductHeader ${vendorId}`}>
              <img src={header_logo} alt={`${vendorId} logo`} />
            </header>
            <div className={''}>
              <h1>Opps…</h1>
              <div className={'product-price'} style={{ fontWeight: 'normal' }}>
                vi kan ikke finne produktet du er ute etter.
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProductPage;
