/* eslint-disable */

import React, { useEffect, useState } from 'react';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import Skeleton from 'react-loading-skeleton';

function ProductImages(props) {
  const [value, setValue] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const slides = [];
    if (props.images.length && !!props.variant.id) {
      const imgs = props.images.filter(i => {
        if (i.variant_ids.includes(parseInt(props.variant.id, 10)) || i.variant_ids.length === 0) {
          return i;
        }
      });
      imgs.forEach(image => {
        slides.push(<img src={image.src} alt={image.alt} />);
      });
      setImages(slides);
    }
  }, [props.images, props.variant]);

  const onChange = value => {
    setValue(value);
  };

  return (
    <div>
      <div className='image-container'>
        {(images.length && (
          <div>
            <Carousel
              offset={0}
              value={value}
              slides={images}
              onChange={onChange}
              animationSpeed={300}
              autoPlay={5000}
              lazyLoad={true}
              minDraggableOffset={5}
              keepDirectionWhenDragging={true}
              infinite={images.length > 1}
              dots={images.length > 1}
              // breakpoints={{
              //
              //    400: {
              //         itemWidth: 260
              //     },
              //
              // }}
            />
          </div>
        )) || (
          <div>
            <Skeleton width={350} height={346} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductImages;
