import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-carrois-gothic';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import ProductPage from './ProductPage';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import SuccessPage from './SuccessPage';
import { envVariables } from './env-variables';
import { trackOverlayClicks } from './vendors/morgenlevering/event-listeners/message';

import './index.css';

if (envVariables.sentryDSN) {
  Sentry.init({
    dsn: envVariables.sentryDSN,
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    tracesSampleRate: envVariables.sentryTraceRate,
    environment: envVariables.sentryEnv
  });
}

trackOverlayClicks();

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <Router>
      <Switch>
        <Route path='/:vendorId/status/:orderId' children={<SuccessPage />} />
        <Route path='/:vendorId/:productId/:variantId' children={<ProductPage />} />
        <Route path='/:vendorId/:productId/' children={<ProductPage />} />
      </Switch>
    </Router>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);
