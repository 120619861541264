import React from 'react';
import { useParams } from 'react-router';

import { PageParams } from './types/page-params';
import { trackPage } from './analytics/analytics';

export function Success({ orderName }: { orderName: string }) {
  const { vendorId } = useParams<PageParams>();

  trackPage();

  return (
    <div className='success'>
      <strong>Takk for at du valgte å handle hos GaitLine.</strong>
      <span>
        Du vil snart motta en e-post som bekrefter din ordre fra GaitLine. Hvis du har noen spørsmål angående din ordre,
        vennligst kontakt GaitLine direkte.
        <br /> <br />
        Ditt ordrenummer er: <br />
        <strong>{orderName}</strong>
      </span>
    </div>
  );
}
