/* eslint-disable */
// @ts-nocheck

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Motion, spring } from 'react-motion';

import ProductImages from './ProductImages';
import ProductDescription from './ProductDescription';
import { AdditionalInformation } from './AdditionalInformation';
import { Success } from './Success';
import ProductNamePrice from './ProductNamePrice';
import { Cancel } from './Cancel';
import { SuccessPageParams } from './types/page-params';
import { envVariables } from './env-variables';

import header_logo from './assets/barbershop-logo-no-white-600_250x@2x.webp';

const verifyPayment = async ({ orderId, vendorId, apiUrl }: { orderId: string; vendorId: string; apiUrl: string }) =>
  await axios({
    method: 'GET',
    url: `${apiUrl}/vipps/status/${orderId}/vendors/${vendorId}`
  }).catch(() => alert('error'));

function SuccessPage() {
  const params = useParams();
  const { orderId, vendorId } = params;
  const [data, setData] = useState({ product: { brand: { name: '' }, images: [], variants: [{ price: 0 }] } });
  const [status, setStatus] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [orderName, setOrderName] = useState('');
  const [currentVariant, setCurrentVariant] = useState({ id: 0, name: '', price: 0, compare_at_price: 0 });

  const api_url = envVariables.apiUrl;

  useEffect(() => {
    async function verifyStatus() {
      if (orderId && vendorId) {
        const statusRequest = await verifyPayment({ orderId, apiUrl: api_url, vendorId });
        setStatus(statusRequest.data);
        setOrderName(orderId);
      }
    }

    verifyStatus();
  }, [orderId, vendorId, api_url]);

  // useEffect(() => {
  //   async function fetchData() {
  //     if (variantId && productId && vendorId) {
  //       const result = await axios({
  //         method: 'GET',
  //         url: `${api_url}/products/${productId}/vendors/${vendorId}`
  //       });
  //       setData(result.data);
  //       setCurrentVariant(result.data.product.variants.find(v => v.id === parseInt(variantId, 10)));
  //     }
  //   }

  //   fetchData();
  // }, [productId, variantId, vendorId]);

  const initialErrorStyle = () => {
    return {
      y: spring(295, { stiffness: 190, damping: 25 })
    };
  };

  const finalErrorStyle = () => {
    return {
      y: spring(-45, { stiffness: 190, damping: 25 })
    };
  };

  const modalStyle = openModal ? finalErrorStyle() : initialErrorStyle();

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className='ProductPage'>


      <div className='container'>
        <div className={''}>
          <div style={{ marginTop: `20px` }} />
          {status.transactionLogHistory && status.transactionLogHistory[0].operation === 'SALE' && (
            <Success orderName={orderName} />
          )}
          {status.transactionLogHistory && status.transactionLogHistory[0].operation === 'CAPTURE' && (
            <Success orderName={orderName} />
          )}
          {status.transactionLogHistory && status.transactionLogHistory[0].operation === 'RESERVE' && (
            <Success orderName={orderName} />
          )}
          {status.transactionLogHistory && status.transactionLogHistory[0].operation === 'CANCEL' && (
            <Cancel orderName={orderName} />
          )}
        </div>
      </div>
    </div>
  );
}

export default SuccessPage;
